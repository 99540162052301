const CLICKABLE_ELEMENT_ATTRIBUTE_NAME = "data-analysis-clickable-id",
    SECTION_ELEMENT_ATTRIBUTE_NAME = "data-analysis-section-id",
    API_URL = "/UT/SaveClickEvent",
    GUID_ELEMENT_ID = "7812340192",
    CHARTS_SET_UP_CUSTOM_EVENT = 'chartsSetUP',
    CLICKABLE_ELEMENT_TYPE = 1,
    SECTION_ELEMENT_TYPE = 0,
    ENABLE_TRACKING_LIB_LOGGING = "EnableTrackingLibLogging";

// Elements that have to be referenced by hand after rendering
// match elements with selectors
const ELEMENTS = [
    {
        selector: '#container_BewegungsdatenRoenne g.highcharts-legend g g g:nth-child(1)',
        id: '9',
        name: 'DashboardRönneEinspeicherungBewegungsdaten',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#container_BewegungsdatenRoenne g.highcharts-legend g g g:nth-child(2)',
        id: '10',
        name: 'DashboardRönneEinspeicherungBewegungsdaten',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#container_SpeicherfuellstandRoenne g.highcharts-legend g g g:nth-child(1)',
        id: '15',
        name: 'DashboardRönneSpeicherfüllstandAktuell',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#container_SpeicherfuellstandRoenne g.highcharts-legend g g g:nth-child(2)',
        id: '16',
        name: 'DashboardRönneSpeicherfüllstandMaximal',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#container_BewegungsdatenKrrak g.highcharts-legend g g g:nth-child(1)', // typo is indendet
        id: '25',
        name: 'DashboardKraakBewegungsdatenEinspeicherung',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#container_BewegungsdatenKrrak g.highcharts-legend g g g:nth-child(2)', // typo is indendet
        id: '26',
        name: 'DashboardKraakBewegungsdatenAusspeicherung',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#container_SpeicherfuellstandKraak g.highcharts-legend g g g:nth-child(1)',
        id: '31',
        name: 'DashboardKraakSpeicherfüllstandAktuell',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#container_SpeicherfuellstandKraak g.highcharts-legend g g g:nth-child(2)',
        id: '32',
        name: 'DashboardKraakSpeicherfüllstandMaximal ',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#speicherkapazitaet_content_roenne g.highcharts-legend g g g:nth-child(1)',
        id: '48',
        name: 'DetailRönneSpeicherinformationenGebuchtVerfügbar',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#speicherkapazitaet_content_roenne g.highcharts-legend g g g:nth-child(2)',
        id: '49',
        name: 'DetailRönneSpeicherinformationenGebucht',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#bewegungsdaten_content_roenne g.highcharts-legend g g g:nth-child(1)',
        id: '55',
        name: 'DetailRönneBewegungsdatenEinspeicherung',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#bewegungsdaten_content_roenne g.highcharts-legend g g g:nth-child(2)',
        id: '56',
        name: 'DetailRönneBewegungsdatenAusspeicherung',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#speicherfuellstand_content_roenne g.highcharts-legend g g g:nth-child(1)',
        id: '63',
        name: 'DetailRönneSpeicherfüllstandMaximal',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#speicherfuellstand_content_roenne g.highcharts-legend g g g:nth-child(2)',
        id: '58',
        name: 'DetailRönneSpeicherfüllstandAktuell',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#speicherkapazitaet_content_kraak g.highcharts-legend g g g:nth-child(1)',
        id: '78',
        name: 'DetailKraakSpeicherkapazitätVerfügbar',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#speicherkapazitaet_content_kraak g.highcharts-legend g g g:nth-child(2)',
        id: '79',
        name: 'DetailKraakSpeicherkapazitätGebucht',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#bewegungsdaten_content_kraak g.highcharts-legend g g g:nth-child(1)',
        id: '85',
        name: 'DetailKraakBewegungsdatenEinspeicherung',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#bewegungsdaten_content_kraak g.highcharts-legend g g g:nth-child(2)',
        id: '86',
        name: 'DetailKraakBewegungsdatenAusspeicherung',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#speicherfuellstand_content_kraak g.highcharts-legend g g g:nth-child(1)',
        id: '92',
        name: 'DetailKraakSpeicherfüllstandMaximal',
        type: CLICKABLE_ELEMENT_TYPE
    },
    {
        selector: '#speicherfuellstand_content_kraak g.highcharts-legend g g g:nth-child(2)',
        id: '93',
        name: 'DetailKraakSpeicherfüllstandAktuell',
        type: CLICKABLE_ELEMENT_TYPE
    }

];

export {
    CLICKABLE_ELEMENT_ATTRIBUTE_NAME,
    SECTION_ELEMENT_ATTRIBUTE_NAME,
    API_URL,
    GUID_ELEMENT_ID,
    CHARTS_SET_UP_CUSTOM_EVENT,
    ELEMENTS,
    CLICKABLE_ELEMENT_TYPE,
    SECTION_ELEMENT_TYPE,
    ENABLE_TRACKING_LIB_LOGGING
}
