import {CLICKABLE_ELEMENT_ATTRIBUTE_NAME, GUID_ELEMENT_ID, SECTION_ELEMENT_ATTRIBUTE_NAME} from './constants';
import sendClickEvent from "./http";
import logg from "./logg";

export default function clickEvent(event) {

    // get the Id of the current tracking entry
    let trackingGuidElement = document.getElementById(GUID_ELEMENT_ID);

    // get the guid
    this.trackingGuid = trackingGuidElement.innerText;

    logg("click event: ");
    logg(event);

    // counting the depth of an element
    let indexCounter = 0;

    // array to store found elements
    let elementsStore = [];

    // get clicked element
    let clickedElement = event.currentTarget;

    // get elements data value
    let clickedElementId = clickedElement.getAttribute(CLICKABLE_ELEMENT_ATTRIBUTE_NAME);

    // add first item to array
    elementsStore.push({
        ElementIndex: indexCounter,
        ElementId: clickedElementId
    });

    // get first parent element
    let parent = clickedElement.parentNode;

    // iterate over all parents until body element is reached
    while (parent.nodeName.toLowerCase() !== "body") {

        // check if attribute is set
        if (parent.hasAttribute(SECTION_ELEMENT_ATTRIBUTE_NAME)) {

            // raise index counter
            indexCounter++;

            // get id
            let elementId = parent.getAttribute(SECTION_ELEMENT_ATTRIBUTE_NAME);

            // attach element to array
            elementsStore.push({
                ElementIndex: indexCounter,
                ElementId: elementId
            });
        }

        // set parent element to higher parent
        parent = parent.parentNode;
    }

    // build data element
    let data = {
        ClientGuid: this.trackingGuid,
        ClickedElements: elementsStore
    };

    // invoke http call, log if whether it worked or not
    sendClickEvent(data).then(() => {
            console.log("Data saved");
            setTimeout(reAddClickEvents,500 );
        }
    ).catch((err) =>
        console.log(err)
    );
}

function reAddClickEvents() {

    logg("add click events again");

    // get all clickable elements
    let clickableElementsNodeList = document.querySelectorAll(`[${CLICKABLE_ELEMENT_ATTRIBUTE_NAME}]`);

    // iterate
    clickableElementsNodeList.forEach(((value) => {

        console.log("Remove click event if possible");
        value.removeEventListener('cick', clickEvent, true);

        // check
        // add click event to element
        value.addEventListener('click', clickEvent, true);

    }));

    logg("adding click events done");

}
