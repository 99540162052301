import {
    CHARTS_SET_UP_CUSTOM_EVENT,
    CLICKABLE_ELEMENT_ATTRIBUTE_NAME,
    ELEMENTS,
    CLICKABLE_ELEMENT_TYPE,
    SECTION_ELEMENT_TYPE, SECTION_ELEMENT_ATTRIBUTE_NAME
} from './constants'
import clickEvent from "./click";
import logg from "./logg";

export default function entry() {

    // wait until highcharts finished rendering
    schedule().then(() => {

        logg("scheduling resolved");

        // add data attributes for elements not initialiy in DOM
        addDataAttributesToElements(ELEMENTS);

        logg("all attributes added to elements");

    });
}

/**
 * This function ought to wait until the rendering of highcharts elements is finally done.
 * @returns {Promise<void>}
 */
function schedule() {

    return new Promise((resolve => {
        // listening to custom event
        window.addEventListener(CHARTS_SET_UP_CUSTOM_EVENT, (event) => {
            logg(event);
            // resolve
            resolve();

        });
    }));

}

function addClickEvents () {

    logg("start adding click events");

    // get all clickable elements
    let clickableElementsNodeList = document.querySelectorAll(`[${CLICKABLE_ELEMENT_ATTRIBUTE_NAME}]`);

    // iterate
    clickableElementsNodeList.forEach(((value) => {

        // add click event to element
        value.addEventListener('click', clickEvent,true);

    }));

    logg("adding click events done");
}


/**
 *
 * @param elements
 */
function addDataAttributesToElements(elements) {

    logg("addDataAttributesToElements function invoked.");

    // store elements not found in this run
    let nullElements = [];

    // iterate over elements
    elements.forEach((value) => {

        // get element
        let element = document.querySelector(value.selector);

        // check if element could be found
        if (element === null) {

            // add element to schedule list
            nullElements.push(value);

        } else {

            // switch based on type
            // set attribute
            if (value.type === CLICKABLE_ELEMENT_TYPE) {

                element.setAttribute(CLICKABLE_ELEMENT_ATTRIBUTE_NAME, value.id);

            } else if (value.type === SECTION_ELEMENT_TYPE) {

                element.setAttribute(SECTION_ELEMENT_ATTRIBUTE_NAME, value.id);
            }
        }
    });

    // check if everything worked
    if (nullElements.length === 0) {

        logg("All element-attributes added!");

        addClickEvents();

    } else {

        // todo: is this still necessary?
        setTimeout(() => {

            addDataAttributesToElements(nullElements);

        },300 );
    }
}

