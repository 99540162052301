import http from 'superagent';
import {API_URL} from "./constants";
import logg from "./logg";

async function sendClickEvent(payload) {

    logg("Sending Message to backend:");
    logg(payload);
    // make http call
    return await http.post(API_URL, payload);

}

export default sendClickEvent;

