import {ENABLE_TRACKING_LIB_LOGGING} from "./constants";

function logg(message) {

    // check if flag necessary is set
    if (window[ENABLE_TRACKING_LIB_LOGGING] === true) {

        // log in console
        console.log(message);
    }

}

export default logg;
