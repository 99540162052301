/*
    Created By Lucas Ratay 18-02-2019

    This is the library's entry point.
 */

import entry from './entry.js'

// self running function, encapsulated
(function() {

    // dom content loaded event attached
    // call entry function
    document.addEventListener('DOMContentLoaded', entry)

})();
